import React from 'react'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import filter from 'lodash/filter'
import cloneDeep from 'fast-clone'
import Consumer from '@spa-ec/components/PersonalizedCockpit/ConsumerList/Consumer'
import { PersonalizedProduct } from '@spa-core/store/app/interfaces'
import { ConsumerListTheme, NAME } from './ConsumerList.theme'
import { getComponentTheme } from '@ic-theme'
import { DUSTBAG_CATEGORY_TYPE_CODE, PRINTER_CATEGORY_TYPE_CODE } from './constants'

const theme: ConsumerListTheme = getComponentTheme<ConsumerListTheme>(NAME, {
    alternateDustbag: true,
})

type ComponentState = {
    sessionConfig: SessionConfig
}

const ConsumerList: React.FC = () => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const extractModels = (categories: string[], products: PersonalizedProduct[]) =>
        filter(
            products,
            (product: PersonalizedProduct) =>
                categories.includes(product.typeCode) && product.code !== null && product.code !== undefined,
        )

    const printerModels: PersonalizedProduct[] = extractModels(
        [PRINTER_CATEGORY_TYPE_CODE],
        sessionConfig.personalizedData?.personalizedProducts,
    ).slice(0)
    const vacuumerModels: PersonalizedProduct[] = extractModels(
        [DUSTBAG_CATEGORY_TYPE_CODE],
        sessionConfig.personalizedData?.personalizedProducts,
    ).slice(0)

    // Start with the printer models
    let consumers: PersonalizedProduct[]

    if (vacuumerModels.length > 0) {
        // When there is at least one vacuum cleaner we done need all three printers
        consumers = cloneDeep(printerModels.slice(0, 2)).concat(vacuumerModels)
        // Now we have alist of 0,1 or two printer models with
        // the vacuumers at the end use the first thre modes in this list
        consumers = consumers.slice(0, 3)
    } else {
        // No vaccum cleaners, a list of printers it is
        consumers = cloneDeep(printerModels.slice(0, 3))
    }

    if (consumers?.length === 0) {
        return null
    }

    return (
        <div key={'num_models_' + consumers.length} className={'flex flex-1 flex-col md:flex-row justify-end'}>
            {consumers.map((consumer: PersonalizedProduct) =>
                consumer ? (
                    <Consumer
                        url={consumer.url}
                        alias={consumer.alias}
                        key={consumer.code}
                        code={consumer.code}
                        brandName={consumer.brandName}
                        imageUrl={consumer.imageUrl}
                        title={consumer.title}
                        typeCode={consumer.typeCode}
                        enkrona={consumer.isEnkrona}
                        alternateDustbag={theme.alternateDustbag}
                    />
                ) : null,
            )}
        </div>
    )
}

export default ConsumerList
