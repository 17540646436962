const stripHtmlTags = (s) => {
  let str = s;
  if (str === null || str === '') return false;
  str = str.toString();
  if (str.indexOf('<') < 0) return str;

  const el = document.createElement('div');
  el.innerHTML = str;
  return el.innerText;
};

export default stripHtmlTags;
