import { Store } from '@spa-core/store'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NAME as customerFeedbackReducerName } from '@spa-core/store/customer-feedback/constants'
import { createSelector } from 'reselect'
import { CustomerFeedback as CustomerFeedbackInterface } from '@spa-core/store/customer-feedback/interfaces'
import { fetchCustomerFeedback } from '@spa-core/store/customer-feedback/actions'
import CustomerFeedbackItem from './CustomerFeedbackItem'
import { str } from '@spa-ec-js/services/localeSvc'
import UILink from '@ui-elem-js/Link'
import classNames from 'classnames'

const DEFAULT_DISPLAY_COUNT: number = 25
const DEFAULT_DEVICE_DISPLAY_COUNT: number = 2

type ComponentState = {
    customerFeedback: CustomerFeedbackInterface[]
}

type Props = {
    displayCount?: number
    deviceDisplayCount?: number
    isCustomerFeedbackPage?: boolean
    link?: boolean
    ssrKey?: string
}

const CustomerFeedback: React.FC<Props> = ({
    displayCount = DEFAULT_DISPLAY_COUNT,
    deviceDisplayCount = DEFAULT_DEVICE_DISPLAY_COUNT,
    isCustomerFeedbackPage = false,
    link = false,
    ssrKey = '',
}) => {
    const dispatch = useDispatch()
    const customerFeedbackStore = ({ reducers }: Store) => reducers[customerFeedbackReducerName].customerFeedback
    const selector = createSelector(
        [customerFeedbackStore],
        (customerFeedbackStore): ComponentState => ({
            customerFeedback: customerFeedbackStore,
        }),
    )
    const { customerFeedback }: ComponentState = useSelector(selector)

    useEffect(() => {
        if (customerFeedback.length < displayCount) {
            const itemsToLoad: number =
                displayCount > deviceDisplayCount || isCustomerFeedbackPage ? displayCount : deviceDisplayCount
            const limit: number = itemsToLoad - customerFeedback.length
            dispatch(fetchCustomerFeedback(limit))
        }
    }, [displayCount, deviceDisplayCount])

    const desktopCustomerFeedbackItems: React.ReactElement = (
        <>
            {customerFeedback.slice(0, displayCount).map((feedback: CustomerFeedbackInterface) => (
                <CustomerFeedbackItem
                    key={feedback.orderCode}
                    feedback={feedback}
                    isCustomerFeedbackPage={isCustomerFeedbackPage}
                    link={link}
                />
            ))}
        </>
    )

    const itemsForDevice: number = isCustomerFeedbackPage ? displayCount : deviceDisplayCount
    const deviceCustomerFeedbackItems: React.ReactElement = (
        <>
            {customerFeedback.slice(0, itemsForDevice).map((feedback: CustomerFeedbackInterface) => (
                <CustomerFeedbackItem
                    key={feedback.orderCode}
                    feedback={feedback}
                    isCustomerFeedbackPage={isCustomerFeedbackPage}
                    link={link}
                />
            ))}
        </>
    )

    return (
        <div data-ssr-key={ssrKey}>
            <div
                className={classNames(
                    'flex gap-1 md:hidden',
                    isCustomerFeedbackPage ? 'flex-col w-full' : 'flex-row flex-wrap w-full justify-around overflow-x-auto',
                )}
            >
                {deviceCustomerFeedbackItems}
            </div>
            <div
                className={classNames(
                    'flex gap-1 hidden md:flex',
                    isCustomerFeedbackPage ? 'flex-col w-full' : 'flex-row flex-wrap w-full justify-around overflow-x-auto',
                )}
            >
                {desktopCustomerFeedbackItems}
            </div>
            <div className={classNames('flex w-full justify-center col-secondary-fg', isCustomerFeedbackPage ? 'hidden' : '')}>
                <UILink to={'/feedback'}>{str('feedback.placeholder')}</UILink>
            </div>
        </div>
    )
}

export default CustomerFeedback
