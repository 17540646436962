import React, { useEffect, useState } from 'react'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as sessionReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAccountProfile } from '@spa-core/store/app/actions'
import GreetingsBanner from '@spa-ec/components/PersonalizedCockpit/GreetingsBanner/GreetingsBanner'
import ConsumerList from '@spa-ec/components/PersonalizedCockpit/ConsumerList/ConsumerList'
import DeliveryInfo from '@spa-ec/components/PersonalizedCockpit/DeliveryInfo/DeliveryInfo'
import RepurchaseBanner from '@spa-ec/components/PersonalizedCockpit/RepurchaseBanner/RepurchaseBanner'
import OfferBanner from '@spa-ec/components/PersonalizedCockpit/OfferBanner/OfferBanner'
import classNames from 'classnames'

enum PostalCodeValidBasestoreAndCountryList {
    INKLCUB_SE = 'inkclub-se',
    INKCLUB_SE_B2E = 'inkclub-se-b2b',
    DAMMSUAGRPASAR_SE = 'dammsugarpasar-se',
}

enum Styles {
    COCKPIT_COLOR = 'cockpit_color',
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    SSRKey: string
}

const PersonalizedCockpit: React.FC<Props> = ({ SSRKey }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[sessionReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const personalizedData: any = sessionConfig?.personalizedData || {}
    const showOneBuyOnlyBanner: boolean = sessionConfig?.showOneBuyOnlyBanner
    const userLoggedIn: boolean = sessionConfig?.userLoggedIn
    const [isIdentified, setIsIdentified] = useState<boolean>(false)
    const [postalCodeValidBasestoreAndCountry] = useState<string>(sessionConfig.currentSiteCountryId)
    const [accountProfileLoaded, setAccountProfileLoaded] = useState<boolean>(false)

    const dispatch = useDispatch()

    useEffect(() => {
        if (personalizedData.userName) {
            setIsIdentified(true)
        }
        if (userLoggedIn && !accountProfileLoaded) {
            // fetching account data for DeliveryInfo.tsx to get a hold of townCity and postCode
            dispatch(fetchAccountProfile())
            setAccountProfileLoaded(true)
        }
    }, [dispatch, accountProfileLoaded])

    return (
        <div data-ssr-key={SSRKey}>
            {isIdentified ? (
                <div key={'cockpit'} className={classNames(Styles.COCKPIT_COLOR, 'e2e-ver-personalized-cockpit mb-2 p-2')}>
                    <div key={'consumer-list'} className="e2e-ver-greetings-consumerlist flex flex-col md:flex-row">
                        <GreetingsBanner
                            personalizedName={personalizedData.userName}
                            hasPersonalizedproducts={personalizedData?.personalizedProducts?.length}
                        />
                        <ConsumerList />
                    </div>
                    {Object.values(PostalCodeValidBasestoreAndCountryList).includes(
                        postalCodeValidBasestoreAndCountry as PostalCodeValidBasestoreAndCountryList,
                    ) ? (
                        <DeliveryInfo isIdentified={isIdentified} />
                    ) : null}

                    <RepurchaseBanner isPersonalizedCockpit={true} />
                </div>
            ) : null}

            {showOneBuyOnlyBanner && !isIdentified ? (
                <OfferBanner personalizedProducts={personalizedData?.personalizedProducts} key={'offer-cockpit'} />
            ) : null}

            {!isIdentified &&
            Object.values(PostalCodeValidBasestoreAndCountryList).includes(
                postalCodeValidBasestoreAndCountry as PostalCodeValidBasestoreAndCountryList,
            ) ? (
                <DeliveryInfo isIdentified={isIdentified} />
            ) : null}
        </div>
    )
}

export default PersonalizedCockpit
