const themeGen = theme => ({ // eslint-disable-line
  minWidth: 230,
  useFavourites: false,
  disableOuterBorders: true,
  disableInnerBorders: true,
  noGutter: false,
  enableOuterPadding: false,
});

export default themeGen;
