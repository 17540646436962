export const NAME: string = 'ColumnCard'

export type ColumnCardTheme = {
    layout?: ColumnCartLayout
}

export enum ColumnCartLayout {
    SINGLE_COLUMN = 'singleColumn',
    DOUBLE_COLUMN = 'doubleColumn',
}
