import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import ImageIcon from '@spa-ec/components/PersonalizedCockpit/ConsumerList/ImageIcon'
import SlimBubble from '@spa-ec-js/displayComponents/Bubble/SlimBubble'
import { DUSTBAG_CATEGORY_TYPE_CODE } from './constants'

enum Styles {
    CONSUMER_SECTION_BACKGROUND_COLOR = 'consumer_section_background_color',
    CONSUMER_SECTION_TEXT_COLOR = 'consumer_section_text_color',
}
interface Props {
    imageUrl: string
    typeCode: string
    url: string
    code: string
    enkrona: boolean
    alias?: string
    brandName: string
    title: string
    alternateDustbag: boolean
}

const Consumer = ({
    imageUrl,
    typeCode,
    url,
    code,
    enkrona,
    alias,
    brandName,
    title,
    alternateDustbag,
}: Props): React.ReactNode => {
    let iconType: string
    if (imageUrl) {
        iconType = 'category-image'
    } else if (typeCode === DUSTBAG_CATEGORY_TYPE_CODE && alternateDustbag) {
        iconType = 'alternateDustbag'
    } else if (typeCode === DUSTBAG_CATEGORY_TYPE_CODE) {
        iconType = 'dustbag'
    } else {
        iconType = 'printer'
    }

    return (
        <div
            className={classnames(
                'mb-2 w-full md:mr-1 last::md:mr-0 cursor-pointer h-12 md:h-24 mdmt-4 md:mt-0',
                Styles.CONSUMER_SECTION_BACKGROUND_COLOR,
            )}
        >
            <Link
                to={url}
                key={code}
                className={classnames('flex items-center h-full relative', Styles.CONSUMER_SECTION_TEXT_COLOR)}
            >
                {enkrona ? (
                    <SlimBubble position={'top_right'} imageSize={'xs'} oneKronaSticker={true}>
                        <ImageIcon iconType={iconType} imageUrl={imageUrl} className={'pr-4 w-1/4 h-12 md:h-16'} />
                    </SlimBubble>
                ) : (
                    <div>
                        <ImageIcon iconType={iconType} imageUrl={imageUrl} className="h-14 md:h-20" />
                    </div>
                )}
                <div className={'ml-2 underline'}>{alias ?? brandName + ' - ' + title}</div>
            </Link>
        </div>
    )
}
export default Consumer
