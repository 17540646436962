// extracted by mini-css-extract-plugin
var _1 = "nfwEnoQGTpX9QOWhqMZg";
var _2 = "CTan5SWnus5RYvoOJ7h5";
var _3 = "NLjdwQQXTBV3_GkTJOj9";
var _4 = "bOew9VcMt3_AsGazxtGh";
var _5 = "xSX5i0qAmFEZKhQPMl5U";
var _6 = "KyBWOgkLayCX7PcsqezD";
var _7 = "hgI98V1oRc_ocEFphvL2";
var _8 = "VvIJeOdr0kX9eNF324rS";
var _9 = "m0_2TiNUqxUzGTtweLrv";
var _a = "r9Cqvbo5iddMbGPbGO_D";
var _b = "SrpRVBRFdNrnodJc5QNI";
var _c = "gVi4xDXOy8LXbQp7xhd3";
export { _1 as "bottom_left", _2 as "bottom_right", _3 as "bubble-font", _4 as "content", _5 as "discount-bubble", _6 as "discount-bubble-image", _7 as "size-l", _8 as "size-m", _9 as "size-s", _a as "size-xs", _b as "top_left", _c as "top_right" }
