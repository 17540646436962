import React from 'react';
import { setupInitialState, mapItems, setupMutations, superConnect, mergeInitialState } from 'core/util/module-utils';
import logger from 'score/logSvc';
import cloneDeep from 'fast-clone';
import Product from './Product';
import TrialProduct from './TrialProduct';
import { NAME as cartReducerName } from '@spa-core/store/cart/constants';
import { MIN_QUANTITY, MAX_QUANTITY } from '@spa-ec-js/components/QuantityCounter/QuantityCounter';
import { setPurchaseQuantity } from '@spa-core/store/app/actions';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { addToCart } from '@spa-core/store/cart/actions';

// Setup module multi/single instance name etc
const multipleInstances = true;
const name = 'MEProductStateCardMod';

const initialState = mergeInitialState({});

const log = logger.getLogger(name);
const conf = { multipleInstances, name, initialState };

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  instance.currentlyAddingToCart = state?.reducers?.[cartReducerName]?.currentlyAddingToCart;
  instance.purchaseQuantity = state?.reducers[appReducerName]?.purchaseQuantity;
  return instance;
};

// ################# ACTIONS  #################

export const actions = (dispatch, _ownProps) => {
  const self = {
    incrementQuantity: (newQuantity, productCode) => {
      if (newQuantity <= MAX_QUANTITY) {
        dispatch(setPurchaseQuantity(productCode, newQuantity));
      }
    },
    decrementQuantity: (newQuantity, productCode) => {
      if (newQuantity >= MIN_QUANTITY) {
        dispatch(setPurchaseQuantity(productCode, newQuantity));
      }
    },
    addToCart: (quantity, productCode, modelCategoryCode) => {
      dispatch(
        addToCart({
          quantity,
          productCode,
          modelCategoryCode,
        })
      );
    },
  };
  return self;
};

// ################# MUTATIONS  #################

const mutations = {};

// ################# MODULE SETUP DON T TOUCH  #################

export const _module = {
  name,
  state: setupInitialState(initialState, conf),
  actions,
  getters,
  mutations: setupMutations(mutations, conf),
};
// ################# RENDER  #################
class MEProductStateCard extends React.Component {
  render() {
    const p = this.props;
    let spinning = p.currentlyAddingToCart[p.code];

    return p.isTrailProduct ? (
      <TrialProduct
        {...p}
        toggle={p.toggle}
        themeUrl={p.themeUrl}
        key={p.code}
        iid={p.code}
        history={p.history}
        categoryCode={p.categoryCode}
        disabledPurchaseButton={p.disabledPurchaseButton}
        spinning={spinning}
      />
    ) : (
      <Product
        {...p}
        toggle={p.toggle}
        themeUrl={p.themeUrl}
        key={p.code}
        iid={p.code}
        history={p.history}
        categoryCode={p.categoryCode}
        stockStatus={p.stockStatus}
        spinning={spinning}
        incrementQuantity={p.incrementQuantity}
        decrementQuantity={p.decrementQuantity}
        quantity={p.purchaseQuantity?.[p.code] || MIN_QUANTITY}
        addToCart={p.addToCart}
      />
    );
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default superConnect(_module, MEProductStateCard);
