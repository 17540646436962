import PropTypes from 'prop-types';
import React from 'react';

export default class Image extends React.Component {
  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   * @property {string} altText
   */
  static get propTypes() {
    return {
      src: PropTypes.string,
      srcset: PropTypes.string,
      srcsetOnHover: PropTypes.string,
      srcsetSizes: PropTypes.string,
      altText: PropTypes.string,
      srcOnHover: PropTypes.string,
      altTextOnHover: PropTypes.string,
      centered: PropTypes.bool,
      maxWidth: PropTypes.string,
      stretch: PropTypes.bool,
    };
  }

  constructor(props) {
    super(props);
    this.imgEl = React.createRef();
    this.state = { w: 0, h: 0 };
    this.handleClick = this.setDimensions.bind(this);
  }

  setDimensions() {
    const img = this.imgEl.current;
    if (img) {
      this.setState({ w: img.naturalWidth, h: img.naturalHeight });
    }
    // if (this.props.altText === 'Startpage_inkclub_spa_desktop') {
    //   console.info('altText = ', this.props.altText);
    //   console.info('img = ', img);
    //   console.info('img.naturalWidth = ', img.naturalWidth);
    //   console.info('img.naturalHeight = ', img.naturalHeight);
    // }
  }
  render() {
    const styles = {};

    if (this.props.minWidth && this.props.mobile) {
      styles.minWidth = this.props.minWidth;
    }

    if (this.props.maxWidth) {
      if (this.props.mobile) {
        styles.maxWidth = '100%';
      } else {
        styles.maxWidth = this.props.maxWidth;
      }
    }

    if (this.props.stretch) {
      styles.width = '100%';
      styles.height = '100%';
    }

    if (this.props.centered) {
      styles.margin = 'auto';
      styles.display = 'block';
    }

    if (this.state.w !== 0 && this.state.h !== 0) {
      styles.aspectRatio = this.state.w + ' / ' + this.state.h;
    }

    return (
      <>
        {this.props.srcOnHover || this.props.srcsetOnHover ? (
          <img
            src={this.props.src}
            srcSet={this.props.srcset}
            style={styles}
            alt={this.props.altTextOnHover}
            sizes={this.props.srcsetSizes}
            onMouseOver={(e) => {
              e.currentTarget.src = this.props.srcOnHover;
              e.currentTarget.srcset = this.props.srcsetOnHover;
            }}
            onMouseOut={(e) => {
              e.currentTarget.src = this.props.src;
              e.currentTarget.srcset = this.props.srcset;
            }}
          />
        ) : (
          <img
            ref={this.imgEl}
            onLoad={() => {
              this.setDimensions();
            }}
            src={this.props.src}
            srcSet={this.props.srcset}
            sizes={this.props.srcsetSizes}
            style={styles}
            alt={this.props.altText}
          />
        )}
      </>
    );
  }
}

Image.defaultProps = {
  maxWidth: '100%',
};
