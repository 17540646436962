import { NAME as DesktopHeaderName, HeaderComponents } from '@spa-ec/components/Header/DesktopHeader.theme';
import { NAME as HeaderName } from '@spa-ec/components/Header/Header.theme';
import { NAME as ContentPageName } from '@spa-ec/routes/ContentPage/ContentPage.theme';
import { NAME as ProductDetailsName } from '@spa-ec/components/ProductDetails/ProductDetails.theme';
import { NAME as PageTitleName } from '@spa-ec/components/StaticPageComponents/PageTitle.theme';
import { NAME as CartTableName } from '@spa-ec/displayComponents/CartTable/CartTable.theme';
import { NAME as LoginName } from '@spa-ec/components/Login/Login.theme';
import { NAME as ProductTableCategoriesName, } from '@spa-ec/components/ProductTable/ProductTableCategory/ProductTableCategory.theme';
import theme from './theme.conf';
const contentPageTheme = {
    showTitleOnContentPageDesktop: true,
    showTitleOnContentPageMobile: true,
};
const headerTheme = {
    showCountrySelector: false,
};
const desktopHeaderTheme = {
    headerComponents: [
        HeaderComponents.SITE_LOGO,
        HeaderComponents.PROMOTIONS,
        HeaderComponents.SEARCH,
        HeaderComponents.MY_ACCOUNT_AND_CART,
    ],
};
const productDetailsTheme = {
    gapSeparator: true,
    showCartIcon: true,
    applyPaddingSubscriptionPanel: false,
};
const pageTitleTheme = {
    fg: 'white',
    bg: theme.palette.meBrown,
};
const cartTableTheme = {
    totalColumnWidth: 'ic-col-1',
    showCartPriceTitle: false,
    showProductUnit: true,
};
const loginTheme = {
    showLoginTitle: false,
};
const productTableCategoryTheme = {
    excludeSameProductFromShelf: true,
};
export default {
    [ContentPageName]: contentPageTheme,
    [HeaderName]: headerTheme,
    [DesktopHeaderName]: desktopHeaderTheme,
    [ProductDetailsName]: productDetailsTheme,
    [PageTitleName]: pageTitleTheme,
    [CartTableName]: cartTableTheme,
    [LoginName]: loginTheme,
    [ProductTableCategoriesName]: productTableCategoryTheme,
};
