import styled from 'styled-components'
import { ContentPageTheme } from './ContentPage.theme'
import { Theme } from '@ic-theme'

interface Props {
    theme: ContentPageTheme & Theme
}

export const StyledStaticPage = styled.div<Props>`
    &&& {
        @media (max-width: 370px) {
            .ICIframe {
                zoom: 0.85;
                transform: scale(0.85);
                transform-origin: 0 0;
                -moz-transform: scale(0.85);
                -moz-transform-origin: 0 0;
            }
        }
        .content {
            a {
                color: ${(props) => props.theme.linkColor || props.theme.col.primary.fg};
                text-decoration: none;
                :hover {
                    color: ${(props) => props.theme.linkColorHover || props.theme.col.primary.peer};
                    text-decoration-color: ${(props) => props.theme.col.primary.peer};
                    text-decoration: underline;
                }
            }
        }
        .side-content {
            :empty {
                display: none !important;
            }
            @media (min-width: 900px) {
                width: ${(props) => props.theme.sideContentWidth};
            }
        }
    }
`
