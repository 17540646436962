import React from 'react';
import styled from 'styled-components';
import { getTheme } from '@ic-theme';
import { str } from 'seco/localeSvc';
import { Pie } from 'deco/ProductImage/ProductImage';

const theme = getTheme('Characteristics', {});

const PieCell = styled.td`
  ${(props) => (props.setWidth ? 'width: 40px' : 'width: 0px')};
  padding-top: ${(props) => (props.responsive ? theme.space / 2 : theme.space)}rem;
  padding-bottom: ${(props) => (props.responsive ? theme.space / 2 : theme.space)}rem;
`;
const StatCell = styled.td`
  padding-top: ${(props) => (props.responsive ? theme.space / 2 : theme.space)}rem;
  padding-bottom: ${(props) => (props.responsive ? theme.space / 2 : theme.space)}rem;
`;

const CharacteristicsTypeComponent = (p) => (
  <>
    <PieCell responsive={p.responsive} setWidth={p.setWidth}>
      <Pie value={p.value / 12} />
    </PieCell>
    <StatCell responsive={p.responsive}>{str(p.stringKey)}</StatCell>
  </>
);

const ResponsiveBlockWithoutStrength = (p) => (
  <>
    {p.acidity ? (
      <tr>
        <CharacteristicsTypeComponent responsive={p.responsive} value={p.acidity} stringKey={'coffee.acidity'} />
      </tr>
    ) : (
      <tr>
        <td />
      </tr>
    )}
    {p.body ? (
      <tr>
        <CharacteristicsTypeComponent responsive={p.responsive} value={p.body} stringKey={'coffee.body'} />
      </tr>
    ) : (
      <tr>
        <td />
      </tr>
    )}
    {p.roasting ? (
      <tr>
        <CharacteristicsTypeComponent responsive={p.responsive} value={p.roasting} stringKey={'coffee.roasting'} />
      </tr>
    ) : (
      <tr>
        <td />
      </tr>
    )}
  </>
);

const NonResponsiveBlockWithoutStrength = (p) => (
  <tr>
    {p.acidity ? (
      <CharacteristicsTypeComponent responsive={p.responsive} value={p.acidity} stringKey={'coffee.acidity'} />
    ) : (
      <td />
    )}
    {p.body ? <CharacteristicsTypeComponent responsive={p.responsive} value={p.body} stringKey={'coffee.body'} /> : <td />}
    {p.roasting ? (
      <CharacteristicsTypeComponent responsive={p.responsive} value={p.roasting} stringKey={'coffee.roasting'} />
    ) : (
      <td />
    )}
  </tr>
);

const ResponsiveBlockWithStrength = (p) => (
  <>
    {p.strength ? (
      <tr>
        <CharacteristicsTypeComponent
          responsive={p.responsive}
          value={p.strength}
          stringKey={'coffee.intensity'}
          setWidth={true}
        />
      </tr>
    ) : (
      <tr>
        <td />
      </tr>
    )}
    {p.acidity ? (
      <tr>
        <CharacteristicsTypeComponent responsive={p.responsive} value={p.acidity} stringKey={'coffee.acidity'} />
      </tr>
    ) : (
      <tr>
        <td />
      </tr>
    )}
    {p.body ? (
      <tr>
        <CharacteristicsTypeComponent responsive={p.responsive} value={p.body} stringKey={'coffee.body'} />
      </tr>
    ) : (
      ''
    )}
    {p.roasting ? (
      <tr>
        <CharacteristicsTypeComponent responsive={p.responsive} value={p.roasting} stringKey={'coffee.roasting'} />
      </tr>
    ) : (
      <tr>
        <td />
      </tr>
    )}
  </>
);

const NonResponsiveBlockWithStrength = (p) => (
  <>
    <tr>
      {p.strength ? (
        <CharacteristicsTypeComponent
          responsive={p.responsive}
          value={p.strength}
          stringKey={'coffee.intensity'}
          setWidth={true}
        />
      ) : (
        ''
      )}
      {p.acidity ? <CharacteristicsTypeComponent responsive={p.responsive} value={p.acidity} stringKey={'coffee.acidity'} /> : ''}
    </tr>
    <tr>
      {p.body ? <CharacteristicsTypeComponent responsive={p.responsive} value={p.body} stringKey={'coffee.body'} /> : ''}
      {p.roasting ? (
        <CharacteristicsTypeComponent responsive={p.responsive} value={p.roasting} stringKey={'coffee.roasting'} />
      ) : (
        ''
      )}
    </tr>
  </>
);
/**
 * @param {Object} p
 * @param {number} p.strength
 * @param {number} p.acidity
 *  @param {number} p.body
 * @param {number} p.roasting
 * @param {boolean} p.responsive
 * @returns {JSX.Element}
 * @constructor
 * @component
 * @example
 * <Characteristics
 * strength={1}
 * acidity={2}
 * body={3}
 * roasting={4}
 * responsive={true}
 * />
 */
const Characteristics = (p) => {
  let tableBody = null;
  if (!p.strength) {
    if (p.responsive) {
      tableBody = (
        <ResponsiveBlockWithoutStrength
          responsive={p.responsive}
          strength={p.strength}
          acidity={p.acidity}
          body={p.body}
          roasting={p.roasting}
        />
      );
    } else {
      tableBody = (
        <NonResponsiveBlockWithoutStrength
          responsive={p.responsive}
          strength={p.strength}
          acidity={p.acidity}
          body={p.body}
          roasting={p.roasting}
        />
      );
    }
  } else if (p.responsive) {
    tableBody = (
      <ResponsiveBlockWithStrength
        responsive={p.responsive}
        strength={p.strength}
        acidity={p.acidity}
        body={p.body}
        roasting={p.roasting}
      />
    );
  } else {
    tableBody = (
      <NonResponsiveBlockWithStrength
        responsive={p.responsive}
        strength={p.strength}
        acidity={p.acidity}
        body={p.body}
        roasting={p.roasting}
      />
    );
  }
  return (
    <table className={'e2e-ver-characteristics'}>
      <tbody>{tableBody}</tbody>
    </table>
  );
};

/**
 * @param {Object} p
 * @param {number} p.strength
 * @param {number} p.acidity
 * @param {number} p.body
 * @param {number} p.roasting
 * @param {boolean} p.responsive
 * @returns {JSX.Element}
 * @constructor
 * @component
 */
export default Characteristics;
