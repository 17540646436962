import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cloneDeep from 'fast-clone';
import { setupInitialState, mapItems, setupMutations, areStatePropsEqual, getModuleState } from 'core/util/module-utils';
import net from 'score/networkSvc';
import Input from 'ic/ui-elem/Input.jsx';
import Button from '@ui-elem/Button/Button';
import logger from 'score/logSvc';
import { str } from 'seco/localeSvc';
import * as EmailValidator from 'email-validator';
import { Icons } from '@ui-elem/Icon/Icon';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

// Setup module multi/single instance name etc
const multipleInstances = false;
const name = 'NewsLetter';

// Mudules data, this is the initial data for an instance
const initialState = {
  email: '',
  errorMessage: '',
  successMessage: '',
  buttonLabel: 'word.send',
};

const log = logger.getLogger(name);

// ################# GETTERS  #################
const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  instance.emailAdressLabel =
    state.serviceData.localeSvc && state.serviceData.localeSvc.strings
      ? state.serviceData.localeSvc.strings['phrase.enter_your_email_here']
      : '...';

  return instance;
};

// ################# ACTIONS  #################

const actions = (dispatch, ownProps) => ({
  submitNewsLetter: () =>
    dispatch(async (_disp, getState) => {
      const sessionConfig = getReducer(appReducerName).sessionConfig;
      const state = getModuleState(getState(), ownProps.iid, conf);
      const email = state.email;
      const url = sessionConfig.newsLetterSubscriptionURL;
      const ctry = sessionConfig.countryIso.toUpperCase();
      const lang = sessionConfig.currentLanguageIsocode.toLowerCase();
      const fName = '';
      const lName = '';
      const fullName = '';
      const portalCode = sessionConfig.portalId;
      const serviceId = sessionConfig.newsLetterServiceId;
      const campaignNo = sessionConfig.newsLetterCampaignNo;
      const securityKey = sessionConfig.neolaneAuthToken;
      const timestamp = sessionConfig.neolaneAuthTimeStamp;
      let validationErrors = false;
      let messageText = '';

      _disp({
        type: 'NEWSLETTER_SET_ERROR_MESSAGE',
        iid: ownProps.iid,
        value: '',
      });
      _disp({
        type: 'NEWSLETTER_SET_SUCCESS_MESSAGE',
        iid: ownProps.iid,
        value: ' ',
      });
      _disp({
        type: 'NEWSLETTER_SET_BUTTON_LABEL',
        value: 'word.send',
      });
      if (email === '') {
        validationErrors = true;
        messageText = 'checkout.form.email.invalid';
        } else if (!EmailValidator.validate(email)) { // eslint-disable-line
        validationErrors = true;
        messageText = 'invalid.email.text';
      }
      if (validationErrors) {
        _disp({
          type: 'NEWSLETTER_SET_ERROR_MESSAGE',
          value: messageText,
        });
      }
      if (!validationErrors) {
        const postData = {
          email,
          fName,
          lName,
          name: fullName,
          ctry,
          lang,
          pc: portalCode,
          sid: serviceId,
          cid: campaignNo,
          en: securityKey,
          ts: timestamp,
        };

        net.postJSONP(url, postData).then(
          (result) => {
            const isValid = result.status;
            if (isValid === 'ok') {
              messageText = 'newsletter.subscription.success';
              _disp({
                type: 'NEWSLETTER_SET_SUCCESS_MESSAGE',
                value: messageText,
              });
            } else {
              messageText = 'newsletter.subscription.fail';
              _disp({
                type: 'NEWSLETTER_SET_ERROR_MESSAGE',
                value: messageText,
              });
            }
            _disp({
              type: 'NEWSLETTER_SET_BUTTON_LABEL',
              value: 'word.sent',
            });
          },
          (error) => {
            log.debug(error);
            messageText = 'newsletter.subscription.fail';
            _disp({
              type: 'NEWSLETTER_SET_ERROR_MESSAGE',
              value: messageText,
            });
            _disp({
              type: 'NEWSLETTER_SET_BUTTON_LABEL',
              value: 'word.send',
            });
          }
        );
      }
    }),
  setNewsLetterEmail: (_key, value) => dispatch({ type: 'NEWSLETTER_SET_EMAIL', iid: ownProps.iid, value }),
});

// ################# MUTATIONS  #################

const mutations = {
  NEWSLETTER_SET_EMAIL: (state, action) => {
    state.successMessage = '';
    state.errorMessage = '';
    state.email = action.value;
    state.buttonLabel = 'word.send';
    state.disable = false;
  },
  NEWSLETTER_SET_ERROR_MESSAGE: (state, action) => {
    state.errorMessage = action.value;
    state.disable = false;
  },
  NEWSLETTER_SET_SUCCESS_MESSAGE: (state, action) => {
    state.successMessage = action.value;
    state.disable = true;
  },
  NEWSLETTER_SET_BUTTON_LABEL: (state, action) => {
    state.buttonLabel = action.value;
  },
};

// ################# MODULE SETUP DONT TOUCH  #################
const conf = { multipleInstances, name, initialState };
export const _module = {
  name, state: setupInitialState(initialState, conf), actions, mutations: setupMutations(mutations, conf) // eslint-disable-line
};
// ################# RENDER  #################

class NewsLetter extends React.Component {
  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const emailAddressLabel = str('phrase.enter_your_email_here') + '...';
    const newsletter = (
      <div className={'form-field ic-col-12 ic-grid'}>
        <div className={'ic-col-9 ic-col_sm-12 form-field'}>
          <Input
            placeholder={emailAddressLabel}
            id={'footer-value'}
            fluid={true}
            value={this.props.email}
            onChange={this.props.setNewsLetterEmail}
            icon={Icons.Envelope}
            errorMessage={str(this.props.errorMessage)}
            successMessage={str(this.props.successMessage)}
          />
        </div>
        <div className={'ic-col-3 ic-col_sm-12 form-field newsletter-button flex items-center'}>
          <Button
            buttonColor={Button.PRIMARY}
            mode={this.props.mode}
            fluid={true}
            onClick={this.props.submitNewsLetter}
            className={'px-4 py-3'}
            buttonText={str(this.props.buttonLabel)}
            disabled={this.props.disable && this.props.disable === true}
          />
        </div>
      </div>
    );
    return <>{newsletter}</>;
  }
}
NewsLetter.contextTypes = { store: PropTypes.object };
const ExportedNewsLetter = connect(getters, actions, undefined, {
  areStatePropsEqual,
})(NewsLetter);

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default ExportedNewsLetter;
