import React from 'react'
import { PersonalizedProduct } from '@spa-core/store/app/interfaces'
import EnKrOffer from '@spa-ec/components/PersonalizedCockpit/OfferBanner/EnKrOffer'
import { getComponentTheme } from '@ic-theme'
import { OfferBannerTheme, NAME } from './OfferBanner.theme'

const theme: OfferBannerTheme = getComponentTheme<OfferBannerTheme>(NAME, {
    enabledOfferBanner: false,
})

interface Props {
    personalizedProducts: PersonalizedProduct[]
}

const OfferCockpit = ({ personalizedProducts = [] }: Props): React.ReactNode => {
    if (!theme.enabledOfferBanner && personalizedProducts.length <= 0) return null

    // Resolve t1 category personalized product
    const resolvedT1CategoryProduct: PersonalizedProduct = personalizedProducts
        .filter((personalizedProduct) => personalizedProduct.isEnkrona)
        .find((personalizedProduct) => personalizedProduct.typeCode === 't1')

    // Resolve t5 category personalized product
    const resolvedT5CategoryProduct: PersonalizedProduct = personalizedProducts
        .filter((personalizedProduct) => personalizedProduct.isEnkrona)
        .find((personalizedProduct) => personalizedProduct.typeCode === 't5')

    return (
        <div key={'offer-cockpit-internal'} className={'e2e-enkr-cockpit flex w-full flex-col md:flex-row content-bkg gap-1'}>
            {resolvedT1CategoryProduct ? <EnKrOffer personalizedProduct={resolvedT1CategoryProduct} /> : null}
            {resolvedT5CategoryProduct ? <EnKrOffer personalizedProduct={resolvedT5CategoryProduct} /> : null}
        </div>
    )
}

export default OfferCockpit
