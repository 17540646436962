import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Button, { ButtonColors } from '@ui-elem/Button/Button';
import logger from 'score/logSvc';
import { getTheme } from '@ic-theme';
import { str } from 'seco/localeSvc';
import { ForNonDesktop, ForDesktop } from 'ic/ui-elem/Responsive';
import { PodImg, ProductImg } from 'deco/ProductImage/ProductImage';
import '../../theme.scss';
import './global.scss';

const theme = getTheme('TrialProduct', {});
const log = logger.getLogger('TrialProduct 🐙');

export const navigation = {
  navigateTo: (history, url) => {
    history.push(url);
  },
};

const TrialProductInfo = styled.div`
  &&& {
    flex-basis: 100%;
    max-width: 100%;
    background-repeat: repeat-y;
    background-color: ${theme.palette.meDark};
    color: white;
    .eliminate-right-padding-in {
      padding-right: 0 !important;
    }
  }
`;

const ProductImgContainer = styled.div`
  &&& {
    /* display: flex;    justify-content: center;    align-items: center; */
    height: 100%;
    .product-img {
      position: relative;
      justify-content: center;
    }
  }
`;

const ButtonContainer = styled.div`
  &&& {
    display: flex;
    position: relative;
    width: 80%;
    .ic-ui-button {
      color: white;
      margin-top: 1rem;
    }
  }
`;

class TrialProduct extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
      text: PropTypes.string,
      disabledPurchaseButton: PropTypes.bool,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.name) {
      return true;
    }
    if (!this.props.text) {
      return true;
    }
    if (!this.props.disabledPurchaseButton) {
      return true;
    }
    return (
      nextProps.text !== this.props.text ||
      nextProps.name !== this.props.name ||
      nextProps.disabledPurchaseButton !== this.props.disabledPurchaseButton
    );
  }

  render() {
    const p = this.props;
    /* ... */
    return (
      <TrialProductInfo className={'ic-col ic-half-margin-b'}>
        <div className={'ic-grid-noGutter eliminate-right-padding-in ic-double-padding-lr'}>
          <div className={'ic-col-4 ic-col_lg-6 ic-col_sm-8 ic-padding-b e2e-ver-trial-text'}>
            <h2>{p.name}</h2>
            <p>{p.text}</p>
            <ButtonContainer>
              <Button
                className={'oneBuyAddToCartButton p-4 w-full'}
                disabled={p.disabledPurchaseButton}
                fluid={true}
                uppercase={true}
                onClick={() => {
                  p.quantityAddToCart(p.count, p.code, p.categoryCode, false, true);
                }}
                loading={p.isLoading}
                buttonText={str('cart.restore.accept')}
              />
            </ButtonContainer>
            <ButtonContainer>
              <Button
                fluid={true}
                className="mt-4 p-4 w-full"
                buttonColor={ButtonColors.PANEL}
                uppercase={true}
                onClick={() => {
                  navigation.navigateTo(p.history, p.url ? p.url : location.pathname.substr(6) + '/p/' + p.code);
                }}
                butonText={str('phrase.read_more')}
              />
            </ButtonContainer>
          </div>
          <div className={'ic-col-8 ic-col_lg-6 ic-col_sm-4'}>
            <ProductImgContainer className={'items-center flex justify-center md:justify-end lg:justify-center'}>
              {p.images ? (
                <>
                  <ForDesktop>
                    <ProductImg
                      code={'trial'}
                      size={'xlarge'}
                      images={p.images}
                      galleryIndex={2}
                      url={'/c/' + p.categoryCode + '/p/' + p.code}
                      alt={p.name}
                      title={p.name}
                    />
                  </ForDesktop>
                  <ForNonDesktop>
                    <ProductImg
                      code={'trial'}
                      size={'xlarge'}
                      images={p.images}
                      galleryIndex={3}
                      url={'/c/' + p.categoryCode + '/p/' + p.code}
                      alt={p.name}
                      title={p.name}
                    />
                  </ForNonDesktop>
                </>
              ) : (
                <PodImg url={'/c/' + p.categoryCode + '/p/' + p.code} alt={p.name} title={p.name} />
              )}
            </ProductImgContainer>
          </div>
        </div>
      </TrialProductInfo>
    );
  }
}

export default TrialProduct;
