// extracted by mini-css-extract-plugin
var _1 = "pRc7ZXrs4uq9y3DZbWxD";
var _2 = "zS2SzM3k8t3lFFni4Szz";
var _3 = "vSeJbacYJDA51OcDHe2H";
var _4 = "leGwyCD56K4xPI1lmiUt";
var _5 = "eIDtQdIdAxiRK9bG6KYg";
var _6 = "QPI0Y7kqB8TnuMIwGqHx";
var _7 = "v2ACeLuYKMlcmSZzG_6w";
var _8 = "uuCa6vNHN78qVurWhxlm";
export { _1 as "PDPImages", _2 as "PDPPlaceholderImage", _3 as "carouselSection", _4 as "imageContainer", _5 as "imageMagnifyingGlassPlus", _6 as "infoMsg", _7 as "infoMsgCorners", _8 as "maxImgWidth" }
