import React, { useState } from 'react'
import Button from '@ui-elem/Button/Button'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import Modal from '@ui-elem-js/Modal'
import { strDef } from '@spa-ec-js/services/localeSvc'
import { NavLink } from 'react-router-dom'

type Props = {
    openDeliveryInfoPopup: boolean
    setOpenDeliveryInfoPopup: Function
    isIdentified: boolean
    personalizedTownCity: string
    fetchDeliveryOptionsForPostalCode: Function
    personalizedPostCode: string
    scrollUp: Function
}

const DeliveryInfoPopup: React.FC<Props> = ({
    openDeliveryInfoPopup,
    setOpenDeliveryInfoPopup,
    isIdentified,
    personalizedTownCity,
    fetchDeliveryOptionsForPostalCode,
    personalizedPostCode,
    scrollUp,
}) => {
    const text3: string = strDef(
        'postcode.text.paragraph1',
        'Leveransalternativ och leveranstider kan variera för olika platser.',
    )
    const text4: string = strDef('postcode.text.paragraph2', 'Du kan också ange ett postnummer.')

    // Handle postcode localy before dispatch, reduces unneccesary re-rendering of page
    const [inputPostcode, setInputPostcode] = useState<string>()

    return (
        <Modal
            mode={'dialog'}
            padding={'none'}
            size={'extra-small'}
            open={openDeliveryInfoPopup}
            onClose={() => setOpenDeliveryInfoPopup(false)}
            className={'ic-inkclub-postcode-lookup'}
            closeIconSize={'large'}
            closeIcon={Icons.TimesCircle}
            key={'modal-postcode'}
        >
            <div className={'e2e-ver-postcode-lookup-popup p-4 mb-2 col-pale'}>
                <div className="text-base font-bold text-center flex flex-row justify-center">
                    {strDef('postalcode-lookup-title', 'Välj din plats ')}
                    <Icon className="ml-2 self-center" icon={Icons.MapMarkerAlt} size={13} />
                </div>
                <p className="text-center mb-2">{text3}</p>
                {isIdentified || (personalizedPostCode && personalizedTownCity) ? (
                    <div className="flex justify-center">
                        <div className="col-panel p-2 mb-2 w-1/2">
                            <p className="text-center items-center">
                                {personalizedPostCode && personalizedTownCity
                                    ? personalizedPostCode + ' ' + personalizedTownCity
                                    : strDef('', 'Välj din adress')}
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center">
                        <NavLink to={'/my-account'} activeClassName="active">
                            <Button
                                onClick={() => setOpenDeliveryInfoPopup(false)}
                                className="e2e-ver-postcode-login-button px-4 py-2"
                                buttonText={strDef('', 'Logga in och hämta din adress')}
                            />
                        </NavLink>
                    </div>
                )}
                <div className="text-center">
                    <p>{text4}</p>
                </div>
                <div className="pt-4 flex justify-center">
                    <input
                        onKeyUp={(e) => {
                            if (e.key === 'Enter' && inputPostcode.length === 5) {
                                setOpenDeliveryInfoPopup(false)
                                fetchDeliveryOptionsForPostalCode(inputPostcode)
                            } else if (!/[0-9]/.test(e.key)) {
                                e.preventDefault()
                            }
                        }}
                        className="e2e-ver-postcode-input ml-2 w-1/2 border"
                        placeholder={strDef('postcode.input.placeholder', 'Postnummer')}
                        maxLength={5}
                        type="tel"
                        name="postCode"
                        onChange={(e) => setInputPostcode(e.target.value)}
                    />
                    <Button
                        onClick={() => {
                            if (inputPostcode.length === 5) {
                                setOpenDeliveryInfoPopup(false)
                                fetchDeliveryOptionsForPostalCode(inputPostcode)
                                scrollUp()
                            }
                        }}
                        className="e2e-ver-postcode-search px-6 py-2 ml-2"
                        buttonText={strDef('text.button.send', 'Skicka')}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default DeliveryInfoPopup
