import React from 'react'
import { Metadata } from '@spa-ec-js/displayComponents/Head'
import ContentSlot from '@spa-ec-js/components/ContentSlot/ContentSlotMod'
import Breadcrumbs from '@spa-ec/components/Breadcrumbs/Breadcrumbs'
import { getComponentTheme, Theme } from '@ic-theme'
import * as cls from './ContentPage.styles.module.scss'
import classNames from 'classnames'
import PageTitle from '@spa-ec/components/StaticPageComponents/PageTitle'
import loadWithSSRFallback from '@ui-elem-js/Loader'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import PersonalizedCockpit from '@spa-ec/components/PersonalizedCockpit/PersonalizedCockpit'
import { createSelector } from 'reselect'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { useSelector } from 'react-redux'
import { Store } from '@spa-core/store'
import { Slots } from './Slots'
import { ContentPageTheme, NAME } from './ContentPage.theme'
import { StyledStaticPage } from './StyledStaticPage'

const SSRCustomerFeedback = loadWithSSRFallback(
    () => import('@spa-ec/components/CustomerFeedback/CustomerFeedback'),
    'CustomerFeedback',
    {
        hydrate: 'always',
        force: true,
    },
)

const HomepageCategorySection = loadWithSSRFallback(
    () => import('@spa-ec/components/HomepageCategorySection/HomepageCategorySection'),
    'HomepageCategorySection',
    {
        hydrate: 'always',
        force: true,
    },
)

const cmsTicketId: string = sessionStorage.getItem('cmsTicketId')

const theme: ContentPageTheme & Theme = getComponentTheme<ContentPageTheme>(NAME, {
    showSideNavigationOnStaticPages: false,
    showTitleOnContentPageDesktop: false,
    showTitleOnContentPageMobile: false,
    showHomepageCategorySection: false,
    sideContentWidth: '25%',
    sideContentPadding: 'px-8',
})

export enum Template {
    STATIC_PAGE = 'STATIC_PAGE',
    CUSTOMER_FEEDBACK = 'CUSTOMER_FEEDBACK',
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    pageId: string
    template: Template
    metadata: any
    isLoggedIn: boolean
    campaignPageId?: string
    showBreadCrumbsOnPage?: boolean
}
const homepageSPAIdSubstring = 'homepageSPA'
const ContentPage: React.FC<Props> = ({ pageId, template, metadata, isLoggedIn, campaignPageId, showBreadCrumbsOnPage }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    return template === Template.STATIC_PAGE || template === Template.CUSTOMER_FEEDBACK ? (
        <StyledStaticPage theme={theme} data-ssr-key={pageId}>
            <Metadata metadata={metadata} appendSiteName={true} />
            {theme.config.showBreadCrumbs ? <Breadcrumbs typeOfPage={'content'} pageId={pageId} /> : null}

            <div className={'flex content-bkg'}>
                {theme.showSideNavigationOnStaticPages ? (
                    <div
                        className={classNames(
                            'e2e-side-nav md:w-1/4 lg:w-1/5 hidden md:block mt-4 content side-content',
                            theme.sideContentPadding,
                        )}
                    >
                        <ContentSlot
                            iid={pageId + '---Section2A'}
                            key={pageId + '---Section2A'}
                            SSRKey={pageId + '---Section2A'}
                            position={'Section2A'}
                            pageId={pageId}
                        />
                    </div>
                ) : null}
                <div className={theme.showSideNavigationOnStaticPages ? 'w-full md:w-3/4 lg:w-4/5 flex-1' : 'w-full'}>
                    <PageTitle title={metadata.title} />
                    {theme.config.paddingInContentBlock ? (
                        <div className={'p-4 content-bkg content'}>
                            <Slots pageId={pageId} template={template} />
                        </div>
                    ) : (
                        <Slots pageId={pageId} template={template} />
                    )}
                </div>
            </div>
        </StyledStaticPage>
    ) : (
        <div data-ssr-key={pageId} className={'min-h-screen'}>
            {showBreadCrumbsOnPage === true && theme.config.showBreadCrumbs && pageId.indexOf('homepage') < 0 ? (
                <Breadcrumbs typeOfPage={'content'} pageId={pageId} />
            ) : null}
            <div className={'e2e-content-page content-page'} key={'content-page'}>
                <Metadata metadata={metadata} appendSiteName={true} />
                <ContentSlot
                    iid={pageId + '---CSAdmin'}
                    key={pageId + '---CSAdmin'}
                    SSRKey={pageId + '---CSAdmin'}
                    position={'CSAdmin'}
                    pageId={pageId}
                    removeCaching={isLoggedIn === true}
                />
                {pageId.indexOf('homepage') > 0 || pageId === 'homepageForMobile' ? (
                    <PersonalizedCockpit key={'the-cockpit'} SSRKey={'the-cockpit'} />
                ) : null}
                {cmsTicketId !== null ? (
                    <div className={classNames('md:mb-2 lg:mb-0 w-full lg:w-4/5', cls.billboard)}>
                        <ContentSlot
                            iid={pageId + '---CampaignBillboard'}
                            key={pageId + '---CampaignBillboard'}
                            SSRKey={pageId + '---CampaignBillboard'}
                            position={'CampaignBillboard'}
                            pageId={pageId}
                            removeCaching={campaignPageId}
                        />
                    </div>
                ) : null}
                <div className={'flex flex-col lg:flex-row e2e-ver-billboard'} key={'content-page-div'}>
                    <div className={classNames('flex-1 my-1', cls['find-model'])} key={'SFM_TopLeftArea'}>
                        <ContentSlot
                            iid={pageId + '---TopLeftArea'}
                            key={pageId + '---TopLeftArea'}
                            SSRKey={pageId + '---TopLeftArea'}
                            position={'TopLeftArea'}
                            pageId={pageId}
                        />
                    </div>
                    <div className={classNames('md:mb-2 lg:mb-0 w-full lg:w-4/5', cls.billboard)}>
                        <ContentSlot
                            iid={pageId + '---Billboard'}
                            key={pageId + '---Billboard'}
                            SSRKey={pageId + '---Billboard'}
                            position={'Billboard'}
                            pageId={pageId}
                            removeCaching={campaignPageId}
                        />
                    </div>

                    <div className={classNames('flex-1 my-1', cls['find-model'])} key={'SFM_TopRightArea'}>
                        <ContentSlot
                            iid={pageId + '---TopRightArea'}
                            key={pageId + '---TopRightArea'}
                            SSRKey={pageId + '---TopRightArea'}
                            position={'TopRightArea'}
                            pageId={pageId}
                        />
                    </div>
                </div>
                <ContentSlot
                    iid={pageId + '---Welcome'}
                    key={pageId + '---Welcome'}
                    SSRKey={pageId + '---Welcome'}
                    position={'Welcome'}
                    pageId={pageId}
                />
                <ContentSlot
                    iid={pageId + '---Highlights'}
                    key={pageId + '---Highlights'}
                    SSRKey={pageId + '---Highlights'}
                    position={'Highlights'}
                    pageId={pageId}
                />
                <ContentSlot
                    iid={pageId + '---Checklist'}
                    key={pageId + '---Checklist'}
                    SSRKey={pageId + '---Checklist'}
                    position={'Checklist'}
                    pageId={pageId}
                />
                <ContentSlot
                    iid={pageId + '---PuffHeader'}
                    key={pageId + '---PuffHeader'}
                    SSRKey={pageId + '---PuffHeader'}
                    position={'PuffHeader'}
                    pageId={pageId}
                />
                {theme.showHomepageCategorySection && pageId === 'homepageSPA' ? <HomepageCategorySection /> : null}
                <ContentSlot
                    iid={pageId + '---Section1'}
                    key={pageId + '---Section1'}
                    SSRKey={pageId + '---Section1'}
                    position={'Section1'}
                    pageId={pageId}
                />
                <ContentSlot
                    iid={pageId + '---Section2A'}
                    key={pageId + '---Section2A'}
                    SSRKey={pageId + '---Section2A'}
                    position={'Section2A'}
                    pageId={pageId}
                />
                {sessionConfig.enableOrderTestimonial && pageId.includes(homepageSPAIdSubstring) ? (
                    <div className="md:block">
                        <SSRCustomerFeedback ssrKey={'CustomerFeedback'} displayCount={4} deviceDisplayCount={2} link={true} />
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default ContentPage
