import React from 'react'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import net from '@spa-core-js/services/networkSvc'
import browserSvc from '@spa-core-js/services/browserSvc'
import { str } from '@spa-ec-js/services/localeSvc'
import styled from 'styled-components'
import { getComponentTheme, Theme } from '@ic-theme'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { NAME, PageTitleTheme } from './PageTitle.theme'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { useSelector } from 'react-redux'
import logger from '@spa-core-js/services/logSvc'

const theme: PageTitleTheme & Theme = getComponentTheme<PageTitleTheme>(NAME, {
    fg: 'white',
    showLogoutIcon: true,
    combineSections: false,
    bottomBorderColor: '',
})

const log = logger.getLogger(NAME)

const StyledTitle = styled.div`
    &&& {
        position: relative;
        background: ${(p) => p.theme.bg || p.theme.col.primary.fg};
        h1 {
            color: ${(p) => p.theme.fg};
            border: ${(p) => (p.theme.noBorder ? '0px' : 'inherit')};
        }
        button {
            position: absolute;
            top: ${(p) => p.theme.space / 2}rem;
            right: 0;
        }
        ${(p) =>
            p.theme.combineSections === true
                ? 'h1{border-bottom: 1px solid ' +
                  p.theme.bottomBorderColor +
                  '; width:98%; margin: 0 auto;padding-bottom:5px; @media (max-width: 580px){width:92%;}}}'
                : 'margin-bottom: 12px'};
    }
`

const logOut = (urlPrefix: string): void => {
    net.get(urlPrefix + '/logout').then(
        () => {
            const message = {
                id: 'logout.success',
                message: str('phrase.you_are_now_logged_out'),
                level: 'info',
            }
            browserSvc.sessionSet('logout_msg', JSON.stringify(message))
            location.href = urlPrefix
        },
        (error) => {
            log.warn('error')
            if (error.status === 200) {
                const message = {
                    id: 'logout.success',
                    message: str('phrase.you_are_now_logged_out'),
                    level: 'info',
                }
                browserSvc.sessionSet('logout_msg', JSON.stringify(message))
            }
            location.href = urlPrefix
        },
    )
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    title: string
}

const PageTitle: React.FC<Props> = ({ title }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)
    return (
        <>
            {title ? (
                <>
                    <StyledTitle className={'flex flex-row ic-me-page-title p-0 print:hidden'} theme={theme}>
                        <h1 className="text-center m-0 ml-16 p-4 w-11/12">{title}</h1>
                        {theme.showLogoutIcon && sessionConfig.userLoggedIn ? (
                            <div className={'flex justify-end w-1/12'}>
                                <Icon
                                    icon={Icons.SignOutAlt}
                                    onClick={() => logOut(sessionConfig.urlPrefix)}
                                    size={32}
                                    className={'e2e-ver-logout color-pale cursor-pointer p-4 ic-me-logout '}
                                />
                            </div>
                        ) : null}
                    </StyledTitle>
                    <div className="hidden print:block">
                        <h1 className="text-center m-0 p-4">{title}</h1>
                    </div>
                </>
            ) : (
                ''
            )}
        </>
    )
}

export default PageTitle
