import { ActionTypes, DEFAULT_CUSTOMER_FEEDBACK_LIMIT } from './constants'

export const fetchCustomerFeedback = (limit: number = DEFAULT_CUSTOMER_FEEDBACK_LIMIT) => ({
    type: ActionTypes.FETCH_CUSTOMER_FEEDBACK,
    payload: {
        limit,
    },
})

export const postCustomerFeedback = (orderCode: string, testimonialText: string) => ({
    type: ActionTypes.POST_CUSTOMER_FEEDBACK,
    payload: {
        orderCode,
        testimonialText,
    },
})
