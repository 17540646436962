import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getComponentTheme } from '@ic-theme'
import localeSvc from '@spa-ec-js/services/localeSvc'
import ColumnCard from './ColumnCard'
import RowCard from './RowCard'
import { get1KrSticker } from '@spa-ec-js/displayComponents/Bubble/SlimBubble'
import { SlimProduct } from '@spa-core/store/products/interfaces'
import { ProductOneBuyStatus } from '@spa-core/store/products/constants'
import { addToCart } from '@spa-core/store/cart/actions'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { CardTheme, NAME } from './Card.theme'

const theme: CardTheme = getComponentTheme<CardTheme>(NAME, {
    showEnKrBackground: false,
})

enum Styles {
    CARD_BACKGOUND = 'card_background',
    ENKR_BACKGROUND = 'enkr_background',
    CARD_DISSCOUNT_CONFIG = 'card_disscount_config',
    CARD_DISSCOUNT_POSITION = 'card_disscount_position',
    CARD_INFO_MESSAGE_CONFIG = 'card_info_message_config',
    CARD_INFO_MESSAGE_POSITION = 'card_info_message_position',
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    slimProduct: SlimProduct
    width: number
    openSubscribePopup: Function
    closeSubscribePopup: Function
    subscriptionIsOpen: boolean
    oneBuyStatus: ProductOneBuyStatus
    showBuyPanel?: boolean
    promoPopupIsOpen?: boolean
    closePromoPopup?: Function
    isRowCard?: boolean
    buyTrackingTriggerName?: string
}

const Card: React.FC<Props> = ({
    slimProduct,
    openSubscribePopup,
    closeSubscribePopup,
    subscriptionIsOpen,
    oneBuyStatus,
    width,
    buyTrackingTriggerName,
    showBuyPanel = false,
    isRowCard = false,
}) => {
    const dispatch = useDispatch()

    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const hasOneKronaSticker: boolean =
        slimProduct.oneBuyOnlyProduct &&
        (oneBuyStatus === ProductOneBuyStatus.SHOW || oneBuyStatus === ProductOneBuyStatus.DISABLED)

    const hasInfoMessage: boolean = !!slimProduct.infoMessage

    const discountedPrice: number = hasOneKronaSticker ? slimProduct.oneBuyOnlyPromotionPrice : slimProduct.discountedPrice

    const hasDiscount: boolean =
        discountedPrice !== undefined &&
        discountedPrice !== null &&
        discountedPrice !== slimProduct.price &&
        slimProduct.price - discountedPrice > 0.5

    const formattedDiscountedPrice: string = sessionConfig.isDecimalPricingEnabled
        ? localeSvc.formatDecPrice(discountedPrice)
        : localeSvc.formatLongPrice(discountedPrice)

    // Promotion positions within the card
    let topRightPromo: any
    let topLeftPromo: any

    // Resolve where to put promotions
    if (hasOneKronaSticker) {
        const sticker = get1KrSticker('m', 'top_right', 'pt-2 pr-2')
        topRightPromo = sticker
    }
    if (hasInfoMessage) {
        topLeftPromo = (
            <div className={Styles.CARD_INFO_MESSAGE_POSITION}>
                <div className={Styles.CARD_INFO_MESSAGE_CONFIG}>{slimProduct.infoMessage}</div>
            </div>
        )
    } else if (!hasOneKronaSticker && hasDiscount) {
        // If there is no 1kr or info message but a discount, display as bubble in the top right spot.
        const percentDiscount: number = Math.round((100 * (slimProduct.price - discountedPrice)) / slimProduct.price)
        const label: string = `-${percentDiscount}%`
        topRightPromo = (
            <div className={Styles.CARD_DISSCOUNT_POSITION}>
                <div className={Styles.CARD_DISSCOUNT_CONFIG}>{label}</div>
            </div>
        )
    }

    let buyButtonHandler = (currentlyAddingKey: string = undefined) => {
        dispatch(
            addToCart({
                quantity: 1,
                productCode: slimProduct.code,
                isEnKrona: hasOneKronaSticker,
                buyTrackingTriggerName,
                currentlyAddingKey,
            }),
        )
    }

    /**
     * if popup is active, choose button behavior for the buttons
     * if popup should contain buy panel, set popup to be activated when clicking on the but button.
     */
    if (showBuyPanel) {
        buyButtonHandler = () => openSubscribePopup?.(slimProduct.code)
    }

    const subscriptionButtonHandler = (): void => {
        openSubscribePopup?.(slimProduct.code)
    }

    const resolvedBackground: string =
        (hasOneKronaSticker && theme.showEnKrBackground) || slimProduct.tags?.includes('highlighted')
            ? Styles.ENKR_BACKGROUND
            : Styles.CARD_BACKGOUND

    let price: string = ''

    if (typeof slimProduct.price !== 'undefined') {
        price = sessionConfig.isDecimalPricingEnabled
            ? localeSvc.formatDecPrice(slimProduct.price)
            : localeSvc.formatLongPrice(slimProduct.price)
    }
    if (isRowCard) {
        return (
            <RowCard
                slimProduct={slimProduct}
                buyButtonHandler={buyButtonHandler}
                subscriptionButtonHandler={subscriptionButtonHandler}
                resolvedBackground={resolvedBackground}
                price={price}
                formattedDiscountedPrice={formattedDiscountedPrice}
                hasDiscount={hasDiscount}
                topRightPromo={topRightPromo}
                topLeftPromo={topLeftPromo}
                oneBuyStatus={oneBuyStatus}
                subscriptionIsOpen={subscriptionIsOpen}
                closeSubscribePopup={closeSubscribePopup}
                showBuyPanel={showBuyPanel}
            />
        )
    }

    return (
        <ColumnCard
            width={width}
            slimProduct={slimProduct}
            resolvedBackground={resolvedBackground}
            topRightPromo={topRightPromo}
            topLeftPromo={topLeftPromo}
            price={price}
            formattedDiscountedPrice={formattedDiscountedPrice}
            hasDiscount={hasDiscount}
            subscriptionIsOpen={subscriptionIsOpen}
            subscriptionButtonHandler={subscriptionButtonHandler}
            buyButtonHandler={buyButtonHandler}
            oneBuyStatus={oneBuyStatus}
            showBuyPanel={showBuyPanel}
            closeSubscribePopup={closeSubscribePopup}
        />
    )
}

export default Card
