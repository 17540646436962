import Icon, { Icons } from '@ui-elem/Icon/Icon'
import React from 'react'
import { getThemeFactory } from '@ic-theme'
import { strDef } from '@spa-ec-js/services/localeSvc'
import classnames from 'classnames'
import { ProductAvailability } from '@spa-core/store/products/constants'

// Default string in case every thing else fails.
const translationFallbacks: any = {
    BUYABLE: 'I lager',
    NOT_BUYABLE: 'Tillfälligt slut',
    PROLONGED_DELIVERY: 'Beställd från leverantör',
}

type Props = {
    stockStatusDescription: string
    availability: ProductAvailability
    isInkyEnhancedSale?: boolean
}

const StockStatus: React.FC<Props> = ({ stockStatusDescription, availability, isInkyEnhancedSale }) => {
    // Note that the StockStatus component takes no reponsibility for the incoming
    // data. It only displays the icon according to the icon value and the string,
    // according to the incoming string key. It is the factory function that is responlible
    // for semantic coherence,

    const th = getThemeFactory('StockStatus', {
        iconName: {
            BUYABLE: Icons.CircleCheck,
            NOT_BUYABLE: Icons.TimesCircle,
            PROLONGED_DELIVERY: Icons.Clock,
        },
        iconType: {
            BUYABLE: 'solid',
            NOT_BUYABLE: 'solid',
            PROLONGED_DELIVERY: 'solid',
        },
        showIcon: true,
    })

    const theme: any = th()

    return (
        <div className={'flex'}>
            {theme.showIcon ? (
                <Icon
                    icon={theme.iconName[availability]}
                    size={14}
                    className={classnames(
                        availability === ProductAvailability.BUYABLE ? 'text-buyable' : '',
                        availability === ProductAvailability.NOT_BUYABLE ? 'text-not_buyable' : '',
                        availability === ProductAvailability.PROLONGED_DELIVERY ? 'text-prolonged_delivery' : '',
                        'self-center',
                    )}
                />
            ) : null}
            <span className={classnames('self-center text-xs md:text-sm ml-1', isInkyEnhancedSale ? 'font-bold' : '')}>
                {strDef(stockStatusDescription, translationFallbacks[availability])}
            </span>
        </div>
    )
}
export default StockStatus
