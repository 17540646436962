import React, { useState } from 'react'
import classnames from 'classnames'
import Carousel from '@ui-elem/Carousel/Carousel'
import Gallery from '@spa-ec/components/ProductDetails/Images/Gallery'
import { get1KrSticker } from '@spa-ec-js/displayComponents/Bubble/SlimBubble.jsx'
import Cheapest from '@spa-ec-js/displayComponents/PrisjaktCheapest/Cheapest'
import * as cls from '@spa-ec-js/components/ProductDetails/Images/Images.styles.module.scss'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import { Product } from '@spa-core/store/products/interfaces'
import MainImage from './MainImage'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { Store } from '@spa-core/store'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

const getImgFromProd = (product: Product, index: number, placeholder: string): string => {
    let fallback: string = product.images?.primary?.large?.url || placeholder
    if (fallback.indexOf('placeholder.png') >= 0) {
        fallback = placeholder
        return fallback
    }
    if (product.images?.large?.length > 0 && !product.images?.large?.[index]) {
        return fallback
    }

    if (product) {
        return product.images?.large?.length > 0 ? product.images?.large?.[index]?.url : fallback
    }

    if (!product.images?.primary?.large?.url) {
      return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100' width='100'%3E Sorry, your browser does not support inline SVG. %3C/svg%3E " // eslint-disable-line
    }

    if (!product) {
    return "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100' width='100'%3E Sorry, your browser does not support inline SVG. %3C/svg%3E " // eslint-disable-line
    }
}

enum Style {
    IMAGE_DISSCOUNT_POSITION = 'image_disscount_position',
    IMAGE_DISSCOUNT_CONFIG = 'image_disscount_config',
    IMAGE_INFO_MESSAGE_POSITION = 'image_info_message_position',
    IMAGE_INFO_MESSAGE_CONFIG = 'image_info_message_config',
}

type ComponentState = {
    sessionConfig: SessionConfig
}

type Props = {
    product: Product
    oneKronaSticker: boolean
    isMasterComponent: boolean
    classes: string
}

const Images: React.FC<Props> = ({ product, oneKronaSticker, isMasterComponent, classes }) => {
    const sessionConfigStore = ({ reducers }: Store) => reducers[appReducerName].sessionConfig
    const selector = createSelector(
        [sessionConfigStore],
        (sessionConfig): ComponentState => ({
            sessionConfig,
        }),
    )
    const { sessionConfig }: ComponentState = useSelector(selector)

    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)
    const [imageGalleryOpen, setImageGalleryOpen] = useState<boolean>(false)

    const hasInfoMessage: boolean = !!product.productInfoMessage
    const hasDiscount: boolean =
        (product.discountedPrice && product.discountedPrice !== product.price) || !!product.discountPercentage

    let topRightPromo: React.ReactElement
    let topLeftPromo: React.ReactElement
    // If there is a 1kr promotion, that one is prioritized and gets the top right spot.
    if (oneKronaSticker) {
        const sticker: React.ReactElement = get1KrSticker('m', 'top_right', 'pt-4 pr-4')
        topRightPromo = sticker
    }
    // If there is a info message it is allways placed to the top left.
    if (hasInfoMessage) {
        topLeftPromo = (
            <div className={classnames('absolute pt-4 pl-4', Style.IMAGE_INFO_MESSAGE_POSITION)}>
                <div className={classnames('px-4 py-2', Style.IMAGE_INFO_MESSAGE_CONFIG)}>{product.productInfoMessage}</div>
            </div>
        )
    } else if (!oneKronaSticker && hasDiscount) {
        // If there is no 1kr or info message but a discount, display as bubble in the top right spot.
        const discountedPct = Math.round((100 * (product.price - product.discountedPrice)) / product.price)
        const label = '-' + discountedPct + '%'
        topRightPromo = (
            <div className={classnames(' pt-4 pr-4', Style.IMAGE_DISSCOUNT_POSITION)}>
                <div className={classnames('p-1 flex items-center p-4', Style.IMAGE_DISSCOUNT_CONFIG)}>{label}</div>
            </div>
        )
    }

    let Img: React.ReactElement = null
    const moreThanOneImage: boolean = product?.images?.large?.length > 1
    /**
     * If there is only one image, show the image
     * if there are more than one image and we should show the carousel on desktop, render the Carousel
     * if there are more than one image and the carousel should be hidden on desktop render both the image and the carousel and use media-queries to select
     * which one that is shown
     */
    if (moreThanOneImage) {
        Img = (
            <div className={cls.maxImgWidth}>
                <Cheapest code={product.code} size={'md1'} />
                <Carousel
                    dotsOutsideComponent={true}
                    initialSlide={selectedImageIndex}
                    setSelectedImageIndex={setSelectedImageIndex}
                >
                    {product.images.large.map((_, index) => {
                        const url: string = getImgFromProd(
                            product,
                            index,
                            `${sessionConfig.themeResourcePath}/images/placeholder.svg`,
                        )
                        return (
                            <MainImage
                                key={product.code}
                                url={url}
                                openGallery={() => setImageGalleryOpen(true)}
                                name={product.name}
                                manufacturer={product.manufacturer}
                                classes={classes}
                            />
                        )
                    })}
                </Carousel>
            </div>
        )
    } else {
        const url: string = getImgFromProd(
            product,
            selectedImageIndex,
            `${sessionConfig.themeResourcePath}/images/placeholder.svg`,
        )
        Img = (
            <div className={cls.maxImgWidth}>
                <Cheapest code={product.code} size={'md1'} />
                <MainImage
                    key={product.code}
                    url={url}
                    openGallery={() => setImageGalleryOpen(true)}
                    name={product.name}
                    manufacturer={product.manufacturer}
                />
            </div>
        )
    }
    return (
        <div
            className={classnames(
                'e2e-pdp-slim-image relative border-panel flex justify-center col-pale',
                isMasterComponent ? 'md:border-1' : '',
                cls.PDPImages,
                cls.carouselSection,
            )}
        >
            {Img}
            {topRightPromo}
            {topLeftPromo}
            <div onClick={() => setImageGalleryOpen(true)}>
                <Icon
                    icon={Icons.MagnifyingGlassPlus}
                    size={22}
                    className={classnames('absolute cursor-pointer pb-1 pr-6', cls.imageMagnifyingGlassPlus)}
                />
            </div>
            {imageGalleryOpen ? (
                <Gallery
                    setImageGalleryOpen={setImageGalleryOpen}
                    selectedImageIndex={selectedImageIndex}
                    setSelectedImageIndex={setSelectedImageIndex}
                    product={product}
                    getImgFromProd={getImgFromProd}
                    classes={classes}
                />
            ) : null}
        </div>
    )
}

export default Images
