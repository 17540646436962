// extracted by mini-css-extract-plugin
var _1 = "elq7_NFx9tUOOy9oRJFs";
var _2 = "qMfohtTIXCvn3dbiG4in";
var _3 = "Tvjjhl9HH8O2rDrzutVZ";
var _4 = "M4s9WHomoS2j1cM9oAEV";
var _5 = "ypUQvZeDDdmKnVMZ47Jh";
var _6 = "_MslV7csFrtuUVWsPHNW";
var _7 = "rvbMDTg6vz91mXoiO8vQ";
var _8 = "zbUslVxyM71PNDbcZgKf";
var _9 = "dveyBFHYJTc4o_ZuZ_yO";
var _a = "vmvKjGmfRSJkGUdp_p9o";
var _b = "DxR5odVtrF4Buj7jyzsw";
var _c = "JjYZ14yHLN9Y28O_4bLR";
var _d = "QfGhzFqpqsvxTzyL3YIg";
export { _1 as "prisjaktlabelwrapper", _2 as "prisjaktlabelwrapperleft-md1", _3 as "prisjaktlabelwrapperleft-md2", _4 as "prisjaktlabelwrapperleft-sm1", _5 as "prisjaktlabelwrapperleft-sm2", _6 as "prisjaktlabelwrapperleft-sm3", _7 as "prisjaktlabelwrapperleft-sm4", _8 as "prisjaktlabelwrapperright-md1", _9 as "prisjaktlabelwrapperright-md2", _a as "prisjaktlabelwrapperright-sm1", _b as "prisjaktlabelwrapperright-sm2", _c as "prisjaktlabelwrapperright-sm3", _d as "prisjaktlabelwrapperright-sm4" }
