const themeGen = theme => ({ // eslint-disable-line
  radioIconColor: 'pale',
  col: {
    primary: {
      col: 'blue',
      altCol: 'yellow',
    },
  },
});

export default themeGen;
