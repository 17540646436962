import React from 'react'
import { str, strDef } from '@spa-ec-js/services/localeSvc'
import Button, { ButtonColors, IconAlignments } from '@ui-elem/Button/Button'
import { Icons } from '@ui-elem/Icon/Icon'
import Modal from '@ui-elem-js/Modal'
import { useCookies } from 'react-cookie'

type Props = {
    openAnonymizerPopup: boolean
    setOpenAnonymizerPopup: Function
}

const AnonymizerPopup: React.FC<Props> = ({ openAnonymizerPopup, setOpenAnonymizerPopup }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [cookies, setCookie, removeCookie] = useCookies(['ID2', 'JSESSIONID'])

    const text1: string = strDef(
        'anonyminizer.text.paragraph1',

        'Vi försöker ge dig en så bra köp-upplevelse som möjligt genom att till exempel hjälpa dig hitta till din skrivare så enkelt som möjligt. För att kunna göra detta identifierar vi dig när du kommer tillbaka till webbplatsen med en så kallad cookie.',
    )
    const text2: string = strDef(
        'anonyminizer.text.paragraph2',
        'Tryck på "Rensa" om du vill ta bort denna cookie vilket gör dig anaonym men medför att vi inte kan erbjuda dig special-anpassad information.',
    )

    return (
        <Modal
            mode={'dialog'}
            padding={'none'}
            size={'extra-small'}
            open={openAnonymizerPopup}
            onClose={() => setOpenAnonymizerPopup(false)}
            className={'ic-inkclub-divider'}
            closeIconSize={'small'}
            radius={'0.735'}
            key={'modal-forget'}
        >
            <div className={'p-8 col-pale-inverted'}>
                <div className="text-x1 font-bold text-center">{strDef('anonyminizer.popup.title', 'Ta bort din historik')}</div>
                <p>{text1}</p>
                <p>{text2}</p>
                <div className="flex justify-around">
                    <Button
                        onClick={() => setOpenAnonymizerPopup(false)}
                        className="px-8 py-2"
                        buttonText={str('text.button.cancel')}
                    />
                    <Button
                        buttonIcon={Icons.Trash}
                        iconAlignment={IconAlignments.RIGHT}
                        onClick={() => {
                            removeCookie('ID2', { path: '/' })
                            removeCookie('JSESSIONID', { path: '/' })
                            location.reload()
                        }}
                        buttonColor={ButtonColors.TERTIARY}
                        className="px-8 py-2"
                        buttonText={strDef('text.button.clear', 'Rensa')}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default AnonymizerPopup
