import logger from 'score/logSvc';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { getThemeFactory } from '@ic-theme';

const log = logger.getLogger('GhostCardPDP');
const th = getThemeFactory('GhostCardPDP', {
  // width: '172px',
  height: '350px',
  imageSize: '150px',
  numTextRows: 6,
  opacity: 0.6,
});

const StyledCard = styled.div`
  &&& {
    opacity: ${(p) => p.theme.opacity};
  }
`;

const FakeImage = styled.div`
  &&& {
    background: ${(p) => p.theme.col.pale.peer};
    height: 300px;
  }
`;
const FakeText = styled.div`
  &&& {
    background: ${(p) => p.theme.col.pale.peer};
    height: 25px;
    border-radius: 10px;
  }
`;
const FakeButton = styled.div`
  &&& {
    background: ${(p) => p.theme.col.primary.fg};
    height: 40px;
    border-radius: ${(p) => p.theme.radius};
  }
`;

const TextRows = (p) => {
  const res = [];
  for (let i = 0; i < p.numTextRows; i++) {
    res.push(<FakeText key={'id-' + i} className={'mt-4 w-full'} />);
  }
  return res;
};

class GhostCardPDP extends React.Component {
  /**
   * propTypes
   * @property {string} src
   * @property {string} centered
   * @property {oneOf} iconAlignment
   */
  static get propTypes() {
    return {};
  }

  render() {
    const theme = th();
    return (
      <ThemeProvider theme={theme}>
        <div className="flex flex-col md:flex-row items-center md:justify-center">
          <FakeImage className="w-4/5 md:w-1/3 mx-8 border-box" />
          <StyledCard className="flex flex-col items-center mx-8 w-4/5 md:items-end md:w-2/3 border-box" {...this.props}>
            <TextRows numTextRows={theme.numTextRows} />
            <FakeButton className={'mt-4 md:w-1/4 sm:w-1/3 w-4/5'} />
          </StyledCard>
        </div>
      </ThemeProvider>
    );
  }
}

GhostCardPDP.defaultProps = {};

export default GhostCardPDP;
