import React from 'react'
import ContentSlot from '@spa-ec-js/components/ContentSlot/ContentSlotMod'
import CustomerFeedback from '@spa-ec/components/CustomerFeedback/CustomerFeedback'
import { Template } from './ContentPage'

interface Props {
    pageId: string
    template: Template
}

export const Slots = ({ pageId, template }: Props): React.ReactNode => (
    <>
        <ContentSlot
            iid={pageId + '---TopBannerArea'}
            key={pageId + '---TopBannerArea'}
            SSRKey={pageId + '---TopBannerArea'}
            position={'TopBannerArea'}
            pageId={pageId}
        />
        <ContentSlot
            iid={pageId + '---Section1'}
            key={pageId + '---Section1'}
            SSRKey={pageId + '---Section1'}
            position={'Section1'}
            pageId={pageId}
        />
        {template === Template.CUSTOMER_FEEDBACK ? (
            <>
                <ContentSlot
                    iid={pageId + '---TopContent'}
                    key={pageId + '---TopContent'}
                    SSRKey={pageId + '---TopContent'}
                    position={'TopContent'}
                    pageId={pageId}
                />
                <CustomerFeedback isCustomerFeedbackPage={true} />
                <ContentSlot
                    iid={pageId + '---BottomContent'}
                    key={pageId + '---BottomContent'}
                    SSRKey={pageId + '---BottomContent'}
                    position={'BottomContent'}
                    pageId={pageId}
                />
            </>
        ) : null}
    </>
)
