import logger from 'score/logSvc';

const log = logger.getLogger(name); // eslint-disable-line

let cache = {};
/**
 * Ex: const getProductByCode = memo('lastUpdate', 'getCode', [0], getCode);
 * @param {*} validator Part of state to validate against previous call
 * @param {*} signature id in cache for this function
 * @param {*} keys - array with positions of the arguments to be considered consider when decidicing of the result has been cached already
 * @param {*} fun
 */
const memo = function memo(validator, signature, keys, fun) {
  return function memm(...args) {
    let key = signature + '_' + (this ? this[validator] : '_');
    keys.forEach((k) => {
      key = key + '_' + args[k];
    });

    if (!cache[key]) {
      cache[key] = fun.apply(this, args);
    }
    return cache[key];
  };
};

export const clear = () => {
  cache = {};
};

export default memo;
