import React from 'react'
import { useDispatch } from 'react-redux'
import CleanText from '@ui-elem-js/CleanText'
import { str } from '@spa-ec-js/services/localeSvc'
import { SlimProduct } from '@spa-core/store/products/interfaces'
import { ProductAvailability } from '@spa-core/store/products/constants'
import { addToCart } from '@spa-core/store/cart/actions'

type Props = {
    slimProduct: SlimProduct
}

const CardBracketPrice: React.FC<Props> = ({ slimProduct }) => {
    const dispatch = useDispatch()
    return (
        <>
            {slimProduct.bracketPrices &&
            slimProduct.bracketPrices.length > 1 &&
            slimProduct.stockStatus &&
            slimProduct.stockStatus.availability !== ProductAvailability.NOT_BUYABLE ? (
                <div className={'font-bold underline text-sm text-center mt-0 mb-2'} key="card-bracket-prices">
                    {slimProduct.bracketPrices.map(({ bracketPrice, quantity }) => (
                        <div key={'bracketPrice_' + quantity}>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault()
                                    dispatch(
                                        addToCart({
                                            quantity,
                                            productCode: slimProduct.code,
                                            modelCategoryCode: undefined,
                                            isEnKrona: false,
                                        }),
                                    )
                                }}
                            >
                                {slimProduct.isSnusProduct ? (
                                    <CleanText
                                        onlyText={true}
                                        value={`${str('bracket.price.snus.msg.quantity', [quantity])} ${str(
                                            'bracket.price.promotion.msg.price_box',
                                            [bracketPrice.longFormattedValue],
                                        )}`}
                                    />
                                ) : (
                                    <CleanText
                                        onlyText={true}
                                        value={`${str('bracket.price.promotion.msg.quantity', [quantity])} ${str(
                                            'bracket.price.promotion.msg.price',
                                            [bracketPrice.longFormattedValue],
                                        )}`}
                                    />
                                )}
                            </a>
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    )
}

export default CardBracketPrice
