import PropTypes from 'prop-types';
import React from 'react';
import logger from 'score/logSvc';
import ContentSlot from 'eco/ContentSlot/ContentSlotMod.jsx';
import { Metadata } from 'deco/Head';
import ProductDetails from 'ic/sites/minespresso/components/MEProductDetails/ProductDetailsMod.jsx';

const log = logger.getLogger('PDP');

export default class ProductDetailPage extends React.Component {
  /**
   * propTypes
   */
  static get propTypes() {
    return {
      pageId: PropTypes.string,
      metadata: PropTypes.object,
      // title: PropTypes.string,
    };
  }

  /**
   * Function called before mounting component. Do initialisations here that should not happen every render, potentially
   * serveral times per second.
   */
  componentDidMount() {}

  /**
   * Render function for react, called very time there is state change.
   * @returns {Html} The rendered code
   */
  render() {
    const productCode = this.props.match.params.productCode;
    return (
      <>
        <Metadata metadata={this.props.metadata} appendSiteName={true} />
        <div className="ic-grid-right-noGutter">
          <ProductDetails iid={productCode} productCode={productCode} />
        </div>
        <ContentSlot
          iid={this.props.pageId + '---ProductRecommendation'}
          key={this.props.pageId + '---ProductRecommendation'}
          SSRKey={this.props.pageId + '---ProductRecommendation'}
          position={'ProductRecommendation'}
          pageId={this.props.pageId}
          product={productCode}
        />
      </>
    );
  }
}
ProductDetailPage.contextTypes = {
  store: PropTypes.object,
};
