import { getTheme } from '@ic-theme';

const theme = getTheme('getColorFromCode', {});

const colorFromCode = (code) => {
  switch (code) {
    case 'Amabile-Malet':
    case 'Amabile-Bonor':
    case 'Amabile':
      return {
        bg: 'rgb(200, 80, 60)',
        fg: 'white',
      };
    case 'Lungo-Malet':
    case 'Lungo-Bonor':
    case 'Lungo':
      return {
        bg: '#475b8a',
        fg: 'white',
      };
    case 'Ristretto-Malet':
    case 'Ristretto-Bonor':
    case 'Ristretto':
      return {
        bg: '#454545',
        fg: 'white',
      };
    case 'Espresso-EKO-Malet':
    case 'Espresso-EKO-Bonor':
    case 'Espresso-EKO':
      return {
        bg: '#698f48',
        fg: 'white',
      };
    case 'Espresso-Malet':
    case 'Espresso-Bonor':
    case 'Espresso':
      return {
        bg: '#775744',
        fg: 'white',
      };
    default:
      return {
        bg: theme.palette.meDarkCream,
        fg: 'white',
      };
  }
};

export default colorFromCode;
