import React from 'react';
import { setupInitialState, mapItems, setupMutations, superConnect, decorate, mergeInitialState } from 'core/util/module-utils';
import Card from '@spa-ec/components/Card/Card';
import cloneDeep from 'fast-clone';

// Setup module multi/single instance name etc
const multipleInstances = true;
const name = 'ProductStateCardMod';

// Modules data, this is the initial data for an instance
const initialState = mergeInitialState({
  subscriptionIsOpen: false,
  buyPopupIsOpen: false,
  promoPopupIsOpen: false,
  showBuyPanel: false,
});

const conf = { multipleInstances, name, initialState };

// ################# GETTERS  #################
export const getters = (state, ownProps) => {
  // Leave this line fetches ta state variable depending on the module is using instances or not
  const instance = cloneDeep(mapItems(state, conf, ownProps.iid));
  return instance;
};

// ################# ACTIONS  #################

export const actions = (dispatch, ownProps) => {
  const self = {
    openSubscribePopup: (code) => {
      dispatch({ type: 'STATE_CARD_CONT_SUB_OPEN', iid: ownProps.iid, code, value: true });
    },
    closeSubscribePopup: (code) => {
      dispatch({ type: 'STATE_CARD_CONT_SUB_CLOSE', iid: ownProps.iid, code, value: false });
      dispatch({ type: 'SUB_PANEL_MOD_CLEAR', iid: ownProps.iid });
    },
    openBuyPopup: () => {
      dispatch({ type: 'STATE_CARD_CONT_BUY_OPEN', iid: ownProps.iid });
    },
    closeBuyPopup: (e) => {
      dispatch({ type: 'STATE_CARD_CONT_BUY_CLOSE', iid: ownProps.iid, value: e.currentTarget.value });
    },
  };
  return self;
};

// ################# MUTATIONS  #################

const mutations = {
  STATE_CARD_CONT_SUB_OPEN: (state, _action) => {
    state.subscriptionIsOpen = true;
  },
  STATE_CARD_CONT_SUB_CLOSE: (state, _action) => {
    state.subscriptionIsOpen = false;
  },
  STATE_CARD_CONT_BUY_OPEN: (state) => {
    state.buyPopupIsOpen = true;
  },
  STATE_CARD_CONT_BUY_CLOSE: (state) => {
    state.buyPopupIsOpen = false;
  },
};

// ################# MODULE SETUP DON T TOUCH  #################

export const _module = decorate({
  name,
  state: setupInitialState(initialState, conf),
  actions,
  getters,
  mutations: setupMutations(mutations, conf),
});
// ################# RENDER  #################
class ProductStateCard extends React.Component {
  render() {
    const p = this.props;
    return (
      <Card
        width={p.width}
        slimProduct={p.basicProduct}
        key={p.basicProduct.code + p.obState}
        openSubscribePopup={p.openSubscribePopup}
        closeSubscribePopup={p.closeSubscribePopup}
        subscriptionIsOpen={p.subscriptionIsOpen}
        showBuyPanel={p.showBuyPanel}
        oneBuyStatus={p.obState}
        staticHeight={p.isCarousel}
        isRowCard={p.cardType === 'rowCard'}
        buyTrackingTriggerName={p.buyTrackingTriggerName}
      />
    );
  }
}

require("core/redux/reducer").registerModule(name, _module);require("@spa-core/redux/store").newModuleLoaded();
export default superConnect(_module, ProductStateCard);
