import React from 'react'
import classnames from 'classnames'

enum Styles {
    CARD_PRICE_TEXT = 'card_price_text',
    CARD_DISCOUNTED_PRICE_TEXT = 'card_discounted_price_text',
    CARD_PRICE_DISCOUNT_ACTIVE = 'card_price_discount_active',
}

type Props = {
    price: string
    formattedDiscountedPrice: string
    hasDiscount: boolean
}

const CardProductPrice: React.FC<Props> = ({ price, formattedDiscountedPrice, hasDiscount }) => {
    return (
        <div className="min-h-9 md:min-h-4">
            {hasDiscount ? (
                <div className={Styles.CARD_PRICE_TEXT}>
                    <span className={Styles.CARD_DISCOUNTED_PRICE_TEXT}>{price}</span>
                    <span className={classnames('font-bold text-lg ml-1', Styles.CARD_PRICE_DISCOUNT_ACTIVE)}>
                        {formattedDiscountedPrice}
                    </span>
                </div>
            ) : (
                <div className={classnames('font-bold text-lg mt-3 md:mt-0', Styles.CARD_PRICE_TEXT)}>{price}</div>
            )}
        </div>
    )
}

export default CardProductPrice
