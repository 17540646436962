import React, { useState } from 'react'
import Icon, { Icons } from '@ui-elem/Icon/Icon'
import { str } from '@spa-ec-js/services/localeSvc'
import SlideInOut from '@ui-elem-js/SlideInOut'
import classNames from 'classnames'
import LastPurchase from '@spa-ec/components/PersonalizedCockpit/RepurchaseBanner/LastPurchase'

enum Styles {
    REPURCHASE_BANNER_COLOR = 'repurchase_banner_color',
}
type Props = {
    isPersonalizedCockpit: boolean
}

const RepurchaseBanner: React.FC<Props> = ({ isPersonalizedCockpit }) => {
    const [showRepurchaseProductsSection, setShowRepurchaseProductsSection] = useState<boolean>(false)
    return (
        <>
            <div className={classNames(Styles.REPURCHASE_BANNER_COLOR, 'flex md:justify-end items-center mb-2 px-4 py-2')}>
                <Icon className={'ml-1 mr-4'} icon={Icons.CartArrowDown} size={36} />
                <div className="flex flex-1 flex-col lg:flex-row md:w-full">
                    <div>
                        <div className="text-x1 hidden lg:block">
                            <strong>{str('personalized.repurchase')}</strong>
                        </div>
                        <div className="hidden md:block">{str('personalized.fill.your.cart')}</div>
                    </div>
                    <div
                        className="flex md:justify-between md:justify-end items-center flex-1 cursor-pointer"
                        onClick={() => setShowRepurchaseProductsSection(!showRepurchaseProductsSection)}
                    >
                        <div className="pr-1 md:pr-4 flex-1 w-fit">
                            <div className="md:hidden text-m grow">{str('personalized.fill.your.cart')}</div>
                            <div>
                                {showRepurchaseProductsSection ? (
                                    <div key={'say_hide'} className="hidden md:block text-m text-left md:text-right md:text-m">
                                        {str('personalized.hide.previous.purchase')}
                                    </div>
                                ) : (
                                    <div key={'say_show'} className="hidden md:block text-m text-left md:text-right md:text-m">
                                        {str('personalized.show.previous.purchase')}
                                    </div>
                                )}
                            </div>
                        </div>
                        <Icon
                            className="e2e-ver-last-purchase-toggle-button"
                            rotate90={showRepurchaseProductsSection}
                            icon={Icons.AngleRight}
                            size={32}
                            onClick={() => setShowRepurchaseProductsSection(!showRepurchaseProductsSection)}
                        />
                    </div>
                </div>
            </div>
            <SlideInOut duration={700} isOpened={showRepurchaseProductsSection}>
                <LastPurchase
                    isPersonalizedCockpit={isPersonalizedCockpit}
                    setShowRepurchaseProductsSection={setShowRepurchaseProductsSection}
                />
            </SlideInOut>
        </>
    )
}

export default RepurchaseBanner
