const themeGen = theme => { // eslint-disable-line
  const res = {
    buttonColor: 'primary',
    panelColor: 'secondary',
    useInitCall: true,
  };
  return res;
};

export default themeGen;
