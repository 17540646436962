import React from 'react'
import classnames from 'classnames'
import * as cls from '@spa-ec-js/components/ProductDetails/Images/Images.styles.module.scss'

type Props = {
    url: string
    name: string
    manufacturer: string
    openGallery?: () => void
    classes?: string
}

const MainImage: React.FC<Props> = ({ url, name, manufacturer, openGallery, classes = '' }) => {
    return (
        <div className="flex w-full justify-center">
            <div className={classnames(url?.includes('placeholder') ? cls.PDPPlaceholderImage : '')}>
                <img
                    src={url}
                    id={'test-main-img'}
                    onClick={() => openGallery()}
                    alt={manufacturer + ' alt ' + name}
                    title={manufacturer + ' ' + name}
                    className={classnames(classes, 'cursor-pointer')}
                />
            </div>
        </div>
    )
}

export default MainImage
