import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Resp from 'react-responsive';
/**
 * @name Responsive
 * @type {number}
 */
export const Responsive = () => <div />;
/**
 * sss
 * @type {number}
 */
export const screenXs = 415;
export const screenSm = 580;
export const screenMd = 899;
export const screenLg = 1218;
/**
 * @name Responsive
 * @deprecated Use media queries in css instead
 * @param props
 * @returns {*}
 * @constructor
 */
export const ForLargeDesktop = (props) => <Resp {...props} minWidth={screenLg} />;
/**
 * @deprecated Use media queries in css instead
 */
export const ForDesktop = (props) => <Resp {...props} minWidth={screenMd + 1} />;
/**
 * @deprecated Use media queries in css instead
 */
export const ForNonDesktop = (props) => <Resp {...props} maxWidth={screenMd} />;
/**
 * @deprecated Use media queries in css instead
 */
export const ForTablet = (props) => <Resp {...props} maxWidth={screenMd} />;
/**
 * @deprecated Use media queries in css instead
 */
export const ForNonTablet = (props) => <Resp {...props} minWidth={screenMd + 1} />;
/**
 * @deprecated Use media queries in css instead
 */
export const ForSmallTablet = (props) => <Resp {...props} maxWidth={screenSm} />;
/**
 * @deprecated Use media queries in css instead
 */
export const ForNonSmallTablet = (props) => <Resp {...props} minWidth={screenSm + 1} />;
/**
 * @deprecated Use media queries in css instead
 */
export const ForMobile = (props) => <Resp {...props} maxWidth={screenXs} />;
/**
 * @deprecated Use media queries in css instead
 */
export const ForNonMobile = (props) => <Resp {...props} minWidth={screenXs + 1} />;

/**
 * @deprecated Use media queries in css instead
 */
const Size = (props) => (
  <>
    {props.xs ? <Resp maxWidth={screenXs}>{props.children}</Resp> : <Fragment key={'noExtraSmall'} />}
    {props.sm ? (
      <Resp minWidth={screenXs + 1} maxWidth={screenSm}>
        {props.children}
      </Resp>
    ) : (
      <Fragment key={'noSmall'} />
    )}
    {props.md ? (
      <Resp minWidth={screenSm + 1} maxWidth={screenMd}>
        {props.children}
      </Resp>
    ) : (
      <Fragment key={'noMedium'} />
    )}
    {props.lg ? (
      <Resp minWidth={screenMd + 1} maxWidth={screenLg}>
        {props.children}
      </Resp>
    ) : (
      <Fragment key={'noLarge'} />
    )}
    {props.xl ? <Resp minWidth={screenLg + 1}>{props.children}</Resp> : <Fragment key={'noExtraLarge'} />}
  </>
);

Size.propTypes = {
  xs: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  children: PropTypes.node,
};
Size.defaultProps = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
};
/**
 * @deprecated Use media queries in css instead
 */
export { Size };
